.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#000, 0.44);

  &:not(.inner) {
    z-index: 99999;
  }

  &.inner {
    position: absolute;
    background-color: rgba(#000, 0.11);
  }
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
}

.loading {
  height: 40px;
  text-align: center;

  > span {
    color: #fff;
  }
}
